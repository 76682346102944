<template>
  <div style="height:100%">
    <portal to="app-header">
      <span>{{ $t('fileManager.title') }}</span>
      <v-btn icon small class="ml-4 mb-1">
        <v-icon>
          {{ '$info' }}
        </v-icon>
      </v-btn>
    </portal>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'FileManagerIndex',
  components: {
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('webApp', ['config', 'storageLocation', 'appSchema']),
  },
  async created() {
    this.loading = true;
    const appId = localStorage.getItem('appId');
    await this.getSchemaAndLabel(appId);
    if (this.appSchema && this.appSchema.modules && this.appSchema.assetId) {
      this.setAssetId(this.appSchema.assetId);
      // this.setModules(this.appSchema.modules);
    }
    await this.fetchModules();
    await this.getMaster();
    await this.getMachines();
    await this.getSubMachines();
    await this.getParts();
    this.loading = false;
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapMutations('fileManager', ['setModules', 'setAssetId']),
    ...mapActions('helper', ['getSchemaAndLabel']),
    ...mapActions('fileManager', ['getMachines', 'getSubMachines', 'getMaster', 'getParts', 'fetchModules']),
  },
};
</script>
